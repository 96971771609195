/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 13, 2021 */
/* Font weights, styles added manually */
/* Thin: 100 */

@font-face {
    font-family: 'Suisse International';
    src: url('./assets/fonts/suisseintl-thin-webfont.woff2') format('woff2'),
         url('./assets/fonts/suisseintl-thin-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Suisse International';
    src: url('./assets/fonts/suisseintl-thinitalic-webfont.woff2') format('woff2'),
         url('./assets/fonts/suisseintl-thinitalic-webfont.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

/* Extra Light: 200 */

@font-face {
    font-family: 'Suisse International';
    src: url('./assets/fonts/suisseintl-ultralight-webfont.woff2') format('woff2'),
         url('./assets/fonts/suisseintl-ultralight-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Suisse International';
    src: url('./assets/fonts/suisseintl-ultralightitalic-webfont.woff2') format('woff2'),
         url('./assets/fonts/suisseintl-ultralightitalic-webfont.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

/* Light: 300 */

@font-face {
    font-family: 'Suisse International';
    src: url('./assets/fonts/suisseintl-light-webfont.woff2') format('woff2'),
         url('./assets/fonts/suisseintl-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Suisse International';
    src: url('./assets/fonts/suisseintl-lightitalic-webfont.woff2') format('woff2'),
         url('./assets/fonts/suisseintl-lightitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

/* Normal: 400 */

@font-face {
    font-family: 'Suisse International';
    src: url('./assets/fonts/suisseintl-regular-webfont.woff2') format('woff2'),
         url('./assets/fonts/suisseintl-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Suisse International';
    src: url('./assets/fonts/suisseintl-regularitalic-webfont.woff2') format('woff2'),
         url('./assets/fonts/suisseintl-regularitalic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

/* Medium: 500 */

@font-face {
    font-family: 'Suisse International';
    src: url('./assets/fonts/suisseintl-medium-webfont.woff2') format('woff2'),
         url('./assets/fonts/suisseintl-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Suisse International';
    src: url('./assets/fonts/suisseintl-mediumitalic-webfont.woff2') format('woff2'),
         url('./assets/fonts/suisseintl-mediumitalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

/* Semi Bold: 600 */

@font-face {
    font-family: 'Suisse International';
    src: url('./assets/fonts/suisseintl-semibold-webfont.woff2') format('woff2'),
         url('./assets/fonts/suisseintl-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Suisse International';
    src: url('./assets/fonts/suisseintl-semibolditalic-webfont.woff2') format('woff2'),
         url('./assets/fonts/suisseintl-semibolditalic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

/* Bold: 700 */

@font-face {
    font-family: 'Suisse International';
    src: url('./assets/fonts/suisseintl-bold-webfont.woff2') format('woff2'),
         url('./assets/fonts/suisseintl-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Suisse International';
    src: url('./assets/fonts/suisseintl-bolditalic-webfont.woff2') format('woff2'),
         url('./assets/fonts/suisseintl-bolditalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

/* Black: 900 */

@font-face {
    font-family: 'Suisse International';
    src: url('./assets/fonts/suisseintl-black-webfont.woff2') format('woff2'),
         url('./assets/fonts/suisseintl-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Suisse International';
    src: url('./assets/fonts/suisseintl-blackitalic-webfont.woff2') format('woff2'),
         url('./assets/fonts/suisseintl-blackitalic-webfont.woff') format('woff');
    font-weight: 900;
    font-style: italic;

}