html,
body {
	margin: 0;
	font-family: "Suisse International", Helvetica, Arial, sans-serif;
	background: #F9F6F0;
	color: #222222;
}
.container:only-child{
	margin-top: 4rem;
}
.container.step:only-child{
	height: 90vh;
	overflow: hidden;
	display: flex;
	flex-flow: column;
}
.row{
	text-align: center;
}
.row .button_accordion{
	margin-top: 2rem;
}

form {
	margin-bottom: 0;
}

header {
	flex: 0 1 auto;

	/* FIRST ATTEMPT AT FADED BUTTONS */
	/*height: calc(5rem + 4px + 1rem + 4rem + 2px + 2rem + 3rem + 6rem + 1rem + 2px + 3rem + 4rem + 4rem);
	position: sticky;
	top: 0;*/
}

section{
	flex: 1 1 auto;
	padding-top: 2rem;
	z-index: 0;
	height: calc(95vh - 30rem);
	overflow: scroll;
	padding-bottom: 8rem;
}
footer {
	flex: 0 1 30rem;
	box-shadow: -10rem -8rem 5rem rgba(249, 246, 240, 1), 10rem -8rem 5rem rgba(249, 246, 240, 1);
	z-index: 1;
	background: rgba(249, 246, 240, 1);
	/* /*background-image: linear-gradient(rgba(249, 246, 240, 0.5), rgba(249, 246, 240, 1)); */
}

/* FOR PROJECT VIEW ROW HEADERS */
.row.row_header{
	text-align: left;
	margin-bottom: 0rem;
}
.step_header .row{
	color: grey;
}
.row.row_header .columns h3{
	margin-bottom: 0rem;
}

@media only screen and (min-width: 500px){
	h1.title_block{
		font-size: 9rem;
	}
	h2.title_block{
		text-align: left;
		font-size: 6rem;
		font-weight: 700;
		margin: 1rem 0 2rem;
	}
	h2.subtitle_block{
		font-size: 6rem;
		text-align: left;
		margin-bottom: 0%;
	}
	h3.subtitle_block{
		margin-top: 1rem;
		font-size: 4rem;
		text-align: left;
		font-weight: 100;
		margin-bottom: 0%;
	}
	p.subtitle_block{
		font-size: 3rem;
		text-align: left;
		font-weight: 100;
		margin-bottom: 0%;
	}
	p.content_block, ul.content_block p, button{
		font-size: 4rem;
		font-weight: 100;
		text-align: left;
	}
	ul.content_block p{
		text-align: left;
	}
	a{
		text-decoration: none;
	}
	p a, ul.content_block p a{
		text-decoration: underline;
		color: #222222;
	}
	.image_block{
		width: 100%;
		margin-bottom: 2rem;
	}
	a button{
		height: 9.5rem;
		color: #FFFFFF;
		border-radius: 4.75rem;
		/*transition: color .3s ease-in-out, background .3s ease-in-out;*/
		border: 1px solid rgb(187, 185, 185)
	}
	button.list_block{
		width: 100%;
		background-color: white;
		border: 2px #222222 solid;
		height: 7rem;
		border-radius: 4.75rem;
		color: #222222;
		margin-bottom: 1rem;
		text-align: center;
		font-size: 2rem;
		font-weight: 400;
	}
	button.list_block:nth-child(2){
		margin-bottom: 6rem;
	}

	button.list_block.completed{
		background-color: #222222;
		color: white;
	}
	button.list_block:disabled{
		border: 2px grey solid;
		color: grey;
	}
	img.portal_graphic{
		width: 100%;
		border-radius: 4rem;
	}
	p.subtext{
		text-align: left;
		margin-top: 2rem;
		margin-left: 3rem;
		margin-right: 1rem;
		font-size: 2rem;
		color: #D2D0D0;
	}
	.img_preview{
		position: relative;
		width: 85%;
		border: 1px solid black;
		border-radius: 2rem;
		margin: 0.5em;
		overflow: hidden;
	}
	.img_preview:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}
	.img_preview img{
		position: absolute;
		left: 0;
		top: 0;
		z-index: 0;
	}
	.close_btn{
		position: absolute;
		width: 42px;
		height: 42px;
		background-color: black;
		border-radius: 40px;
		border: white 4px solid;
		color: white;
		font-size: 24px;
		text-align: center;
		line-height: 47px;
		right: -24px;
		top: -24px;
		z-index: 100;
	}
	/* FOR STEP PAGES */
	.button_accordion .columns:first-child {
		float: left;
	}
	.button_accordion .columns:nth-child(2) {
		float: right;
	}
	button.prev_button, button.next_button{
		width: 100%;
		height: 9.5rem;
		font-size: 3.5rem;
		font-weight: 700;
		line-height: 10.25rem;
		border-radius: 4.75rem;
		border: 2px #222222 solid;
		background-color: #222222;
		color: white
	}
	button.prev_button{
		text-align: right;
		padding-right: 5rem;
		background-image: url(/src/assets/icons/icon_arrow_left_white.svg);
		background-position: 20% 3.75rem;
		background-repeat: no-repeat;
		background-size: 4.75rem auto;
	}
	button.next_button{
		padding-left: 5rem;
		text-align: left;
		background-image: url(/src/assets/icons/icon_arrow_right_white.svg);
		background-repeat: no-repeat;
		background-size: 4.75rem auto;
		background-position: 85% 3.75rem;
	}
	button:disabled.next_button.uploading{
		background-color: white;
		background-image: url(/src/assets/icons/icon_arrow_right_grey.svg);
		background-repeat: no-repeat;
		background-size: 4.75rem auto;
		color: #222222;
		border: 2px #222222 solid;
		background-position: 85% 3.75rem;
	}
	button:disabled.next_button{
		color: white;
		border: 2px white solid;
		background-color: grey;
		font-weight: 700;
	}

	/* FOR CASE FORMS*/
	.form_fields{
		text-align: left;
		font-size: 4rem;
	}
	.form_fields label{
		font-weight: 300;
		margin-top: 0.5rem;
		margin-bottom: 3rem;
	}
	.form_fields input[type=text], .form_fields input[type=tel], .form_fields input[type=email]{
		width: 100%;
		min-height: 8rem;
		padding: 2rem 4rem;
		font-size: 4rem;
		border-radius: 4rem;
		border: 2px #222222 solid;
		background: #FFFFFF;
		color: #222222;
		margin-bottom: 2rem;
	}
	.form_fields input[type=text]:disabled{
		background: #F5F5F5;
		font-style: italic;
		font-weight: 100;
	}
	.form_fields input[type=text].regexed{
		text-transform: uppercase;
	}
	.form_fields input[type=radio]{
		width: 3rem;
		height: 3rem;
		margin: 2rem 4rem 6rem 2rem; /* top | right | bottom | left */
		transform: scale(2);
	}
	.pic_upload{
		margin: 0 auto;
		margin-bottom: 2.5rem;
		text-align: center;
		width: 9.5rem;
		height: 9.5rem;
		border-radius: 4.75rem;
		border: 4px #222222 solid;
		color: #222222;
		margin-top: 4rem;
		background: url("./assets/icons/camera.svg") #FFFFFF;
		background-size: 8rem 8rem;
		background-position: 0.825rem 0.5rem;
		background-repeat: no-repeat;
		vertical-align: middle;
	}
	.pic_upload.disabled{
		border: 4px #B5B5B5 solid;
		color: #B5B5B5;
		background: url("./assets/icons/camera_disabled.svg") #FFFFFF;
		background-size: 8rem 8rem;
		background-position: 0.825rem 0.5rem;
		background-repeat: no-repeat;
		vertical-align: middle;
	}
	.form_fields .pic_upload input[type=file]{
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		min-height: 12rem;
		opacity: 0;
		z-index: 1;
	}
	.form_fields select{
		width: 100%;
		min-height: 8rem;
		padding: 1rem 4rem;
		font-size: 4rem;
		border-radius: 4rem;
		border: 2px #222222 solid;
		background: #FFFFFF;
		color: #222222;
		-webkit-appearance: none;
	}
	.uploading {
		text-align: center;
		width: 9.5rem;
		height: 9.5rem;
		margin: 0 auto;
		position: relative;
	}
	.uploading .border{
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 5rem;
		width: 100%;
		height: 100%;
		border: 4px grey dashed;
		color: #222222;
		background: #FFFFFF;
		/*background-position: center;*/
		background-repeat: no-repeat;
		vertical-align: middle;
		animation: Rotate;
		animation-duration: 2s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		
		-webkit-animation-name: Rotate-webkit;
		-webkit-animation-duration: 2s;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-timing-function: linear;

		-moz-animation-name: Rotate;
		-moz-animation-duration: 2s;
		-moz-animation-iteration-count: infinite;
		-moz-animation-timing-function: linear;
		
		-ms-animation-name: Rotate;
		-ms-animation-duration: 2s;
		-ms-animation-iteration-count: infinite;
		-ms-animation-timing-function: linear;
	}
	.uploading img {
		position: absolute;
		left: 1.625rem;
		top: 1.5rem;
		width: 7rem;
		height: 7rem;
	}
	@keyframes Rotate {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
	@-webkit-keyframes Rotate {
		from {
			-webkit-transform: rotate(0deg);
		}
		to {
			-webkit-transform: rotate(360deg);
		}
	}
	@-moz-keyframes Rotate {
		from {
			-moz-transform: rotate(0deg);
		}
		to {
			-moz-transform: rotate(360deg);
		}
	}
	@-ms-keyframes Rotate {
		from {
			-ms-transform: rotate(0deg);
		}
		to {
			-ms-transform: rotate(360deg);
		}
	}
	p.warning{
		margin-top: 1rem;
		color: red;
	}
	.form_fields textarea{
		width: 100%;
		min-height: 24rem;
		padding: 2rem 4rem;
		font-size: 4rem;
		line-height: 4.5rem;
		border-radius: 4rem;
		border: 2px #222222 solid;
		background: #FFFFFF;
		color: #222222;
	}

	.room_i_container{
		width: 100%;
		display: flex;
	}
	.room_i{
		height: 3rem;
		width: 3rem;
		margin: 1.25rem 0.5rem;
		border-radius: 2.5rem;
		border: 4px #222222 solid;
		background: #222222;
	}
	.room_i.incomplete{
		background: lightgray;
	}
	.room_i.untouched{
		background: none;
	}

	/* Attempt to remove blue outline in iOS */
	:focus {
		outline: 0 !important;
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
		/*-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;*/
	}
}